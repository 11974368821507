<template>
  <div class="attendance-container">
    <el-row :gutter="24">
      <el-col :span="8">
        <grid-container title="管理人员出勤率(当日)">
          <div id="manager-attendance-chart" style="width: 100%;height:311px;"></div>
        </grid-container>
      </el-col>
      <el-col :span="8">
        <grid-container title="劳务人员出勤率(当日)">
          <div id="labor-attendance-chart" style="width: 100%;height:311px;"></div>
        </grid-container>
      </el-col>
      <el-col :span="8">
        <grid-container title="关键岗位出勤率（当日）">
          <div class="post-table">
            <el-row>
              <el-col :span="8"><div class="th">施工单位</div></el-col>
              <el-col :span="8"><div class="th center">当月出勤天数</div></el-col>
              <el-col :span="8"><div class="th">出勤率</div></el-col>
            </el-row>
            <el-row>
              <el-col :span="8"><div class="td">项目经理</div></el-col>
              <el-col :span="8"><div class="td center num">18</div></el-col>
              <el-col :span="8"><div class="td num">26%</div></el-col>
            </el-row>
            <el-row>
              <el-col :span="8"><div class="td">安全员</div></el-col>
              <el-col :span="8"><div class="td center num">26</div></el-col>
              <el-col :span="8"><div class="td num">77%</div></el-col>
            </el-row>
            <el-divider />
            <el-row>
              <el-col :span="8"><div class="th">施工单位</div></el-col>
              <el-col :span="8"><div class="th center">当月出勤天数</div></el-col>
              <el-col :span="8"><div class="th">出勤率</div></el-col>
            </el-row>
            <el-row>
              <el-col :span="8"><div class="td">项目经理</div></el-col>
              <el-col :span="8"><div class="td center num">18</div></el-col>
              <el-col :span="8"><div class="td num">26%</div></el-col>
            </el-row>
          </div>
        </grid-container>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="24">
        <grid-container title="近7日考勤情况统计">
          <div id="week-attendance-chart" style="width: 100%;height:311px;"></div>
        </grid-container>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="24">
        <grid-container title="月度考勤情况统计">
          <div id="month-attendance-chart" style="width: 100%;height:311px;"></div>
        </grid-container>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import GridContainer from '@/components/Grid-container'
export default {
  name: 'Attendance',
  components: {
    GridContainer
  },
  data(){
    return {
      myChart:null,
      myLaborChart:null,
      myWeekChart:null,
      myMonthChart:null
    }
  },
  methods:{
    drawMonthBar(){
      // 基于准备好的dom，初始化echarts实例
      var that = this;
        this.myMonthChart = this.$echarts.init(document.getElementById('month-attendance-chart'));
        //点击事件
        // this.myChart.on('click', function(param) {
        //   console.log(param);
        //   console.log(param.name);
        //   console.log(param.value);
        // });
        // 指定图表的配置项和数据
        var option = {
          title: {
              text: ''
          },
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            containLabel: true
          },
          xAxis: {
              data: ["1日","2日","3日","4日","5日","6日","7日","8日","9日","10日","11日","12日","13日","14日","15日","16日","17日","18日","19日","20日","21日","22日","23日","24日","25日","26日","27日","28日","29日","30日"],
          },
          yAxis: {},
          series: [{                               //新的一个柱子 注意不设stack
            name: '一建',       
            type: 'line',
            stack: '出勤率', 
            data: [200, 200, 200, 200, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '二建',       
            type: 'line',
            stack: '出勤率', 
            data: [200, 200, 200, 200, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '三建',       
            type: 'line',
            stack: '出勤率', 
            data: [200, 200, 200, 200, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '四建',       
            type: 'line',
            stack: '出勤率', 
            data: [200, 200, 200, 200, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '五建',       
            type: 'line',
            stack: '出勤率', 
            data: [200, 200, 200, 200, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '六建',       
            type: 'line',
            stack: '出勤率', 
            data: [200, 200, 200, 200, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '七建',       
            type: 'line',
            stack: '出勤率', 
            data: [200, 200, 200, 200, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '八建',       
            type: 'line',
            stack: '出勤率', 
            data: [200, 200, 200, 200, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '九建',       
            type: 'line',
            stack: '出勤率', 
            data: [200, 200, 200, 200, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '十建',       
            type: 'line',
            stack: '出勤率', 
            data: [200, 200, 200, 200, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '十一建',       
            type: 'line',
            stack: '出勤率', 
            data: [200, 200, 200, 200, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '十二建',       
            type: 'line',
            stack: '出勤率', 
            data: [200, 200, 200, 200, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '十三建',       
            type: 'line',
            stack: '出勤率', 
            data: [200, 200, 200, 200, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 122, 40, 72, 20, 20, 40, 10, 40, 10],
          },]
        };

        // 使用刚指定的配置项和数据显示图表。
        that.myMonthChart.setOption(option);
    },
    drawWeekBar(){
      // 基于准备好的dom，初始化echarts实例
      var that = this;
        this.myWeekChart = this.$echarts.init(document.getElementById('week-attendance-chart'));
        // 指定图表的配置项和数据
        var option = {
          title: {
              text: ''
          },
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            containLabel: true
          },
          xAxis: {
              data: ["1日","2日","3日","4日","5日","6日","7日"],
          },
          yAxis: {},
          series: [{                               //新的一个柱子 注意不设stack
            name: '一建',       
            type: 'line',
            stack: '出勤率', 
            data: [122, 40, 72, 20, 20, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '二建',       
            type: 'line',
            stack: '出勤率', 
            data: [102, 40, 72, 20, 20, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '三建',       
            type: 'line',
            stack: '出勤率', 
            data: [92, 40, 72, 20, 20, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '四建',       
            type: 'line',
            stack: '出勤率', 
            data: [88, 40, 72, 20, 20, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '五建',       
            type: 'line',
            stack: '出勤率', 
            data: [77, 40, 72, 20, 20, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '六建',       
            type: 'line',
            stack: '出勤率', 
            data: [66, 40, 72, 20, 20, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '七建',       
            type: 'line',
            stack: '出勤率', 
            data: [62, 40, 72, 20, 20, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '八建',       
            type: 'line',
            stack: '出勤率', 
            data: [60, 40, 72, 20, 20, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '九建',       
            type: 'line',
            stack: '出勤率', 
            data: [58, 40, 72, 20, 20, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '十建',       
            type: 'line',
            stack: '出勤率', 
            data: [57, 40, 72, 20, 20, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '十一建',       
            type: 'line',
            stack: '出勤率', 
            data: [55, 40, 72, 20, 20, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '十二建',       
            type: 'line',
            stack: '出勤率', 
            data: [38, 40, 72, 20, 20, 40, 10],
          },{                               //新的一个柱子 注意不设stack
            name: '十三建',       
            type: 'line',
            stack: '出勤率', 
            data: [36, 40, 72, 20, 20, 40, 10],
          }]
        };

        // 使用刚指定的配置项和数据显示图表。
        that.myWeekChart.setOption(option);
    },
    drawLaborBar(){
      // 基于准备好的dom，初始化echarts实例
      var that = this;
        this.myLaborChart = this.$echarts.init(document.getElementById('labor-attendance-chart'));
        // 指定图表的配置项和数据
        var option = {
          title: {
              text: ''
          },
          tooltip: {
            // trigger: 'axis',
            // axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            //   type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            // }
          },
          grid: {
            containLabel: true
          },
          xAxis: {
              data: ["一建","二建","三建","四建","五建","六建","七建","八建","九建","十建","十一建","十二建","十三建"],
          },
          yAxis: {},
          series: [{                               //新的一个柱子 注意不设stack
            name: '出勤率',       
            type: 'bar',  
            barWidth: '60%',
            // label: {                 
            //   normal: {
            //       show: true, //显示数值
            //       position: 'top',       //  位置设为top
            //       formatter: '{c}',
            //       textStyle: { color: '#000' } //设置数值颜色
            //   }
            // },
            itemStyle: {
                normal: {
                    color: 'rgba(123, 223, 182, 1)'   // 设置柱背景颜色
                }
            },
            data: [22, 40, 72, 20, 20, 40, 10, 40, 72, 20, 20, 40, 25],
          }]
        };

        // 使用刚指定的配置项和数据显示图表。
        that.myLaborChart.setOption(option);
    },
    drawLine(){
      // 基于准备好的dom，初始化echarts实例
      var that = this;
        this.myChart = this.$echarts.init(document.getElementById('manager-attendance-chart'));
        //点击事件
        // this.myChart.on('click', function(param) {
        //   console.log(param);
        //   console.log(param.name);
        //   console.log(param.value);
        // });
        // 指定图表的配置项和数据
        var option = {
          title: {
              text: ''
          },
          tooltip: {},
          legend: {
              data:['工资发放人数','未发工资人数']
          },
          grid: {
            containLabel: true
          },
          xAxis: {
              data: ["一建","二建","三建","四建","五建","六建","七建","八建","九建","十建","十一建","十二建","十三建"],
          },
          yAxis: {},
          series: [{                               //新的一个柱子 注意不设stack
            name: '出勤率',       
            type: 'bar',  
            barWidth: '60%',
            // label: {                 
            //   normal: {
            //       show: true, //显示数值
            //       position: 'top',       //  位置设为top
            //       formatter: '{c}',
            //       textStyle: { color: '#000' } //设置数值颜色
            //   }
            // },
            itemStyle: {
                normal: {
                    color: 'rgba(91, 143, 249, 1)'   // 设置柱背景颜色
                }
            },
            data: [22, 40, 72, 20, 20, 40, 10, 40, 72, 20, 20, 40, 30],
          }]
        };

        // 使用刚指定的配置项和数据显示图表。
        that.myChart.setOption(option);
    }
  },
  mounted(){
    this.drawLine()
    this.drawLaborBar()
    this.drawWeekBar()
    this.drawMonthBar()
  }
}
</script>
<style lang="stylus" scoped>
.attendance-container
  margin 24px
  padding 10px
  .post-table
    padding 20px 24px
    .th
      color rgba(0, 0, 0, 0.85)
      font-size 14px
    .td
      color rgba(0, 0, 0, 0.85)
      font-size 16px
    .center
      text-align center
    .num
      font-size 30px
    

  .el-row
    margin-bottom 24px
    &:last-child
      margin-bottom 0
    

</style>